import styled from "styled-components";

interface StyledSmallTitleProps {
    textColor: string;
    backgroundColor: string;
}

export const StyledSmallTitle = styled.div<StyledSmallTitleProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  width: 180px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-family: bely;

  @media only screen and (max-width: ${680}px) {
    width: 120px;
    height: 42px;
    line-height: 42px;
  }
`;
