import React, {FC} from "react";
import {StyledSmallTitle} from "./SmallTitle.style";
import {Translate} from "../../../lang/Translate.component";

interface SmallTitleProps {
    title: string;
    textColor: string;
    backgroundColor: string;
}

export const SmallTitle: FC<SmallTitleProps> = (props: SmallTitleProps) => {
    return <StyledSmallTitle backgroundColor={props.backgroundColor} textColor={props.textColor}>
        <Translate translationKey={props.title}/>
    </StyledSmallTitle>;
};
