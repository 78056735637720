import styled from "styled-components";
import {SM_MAX_SIZE, XS_MAX_SIZE} from "../../../common/util/ViewSizeUtils";
import {Colors} from "../../../common/style/ColorUtils";

interface StyledPricingSectionProps {
    backgroundColor: string;
    textColor: string;
}

export const StyledPricingSection = styled.div<StyledPricingSectionProps>`
  ${props => props.backgroundColor === Colors.BLACK ? "" : "background-color: " + props.backgroundColor + ";"}
  background-color:;
  color: ${props => props.textColor};

  padding: 48px 150px;
  white-space: nowrap;

  @media only screen and (max-width: ${SM_MAX_SIZE}px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (max-width: ${680}px) {
    font-size: 20px;
    padding-bottom: 24px;
    padding-top: 24px;
  }

  @media only screen and (max-width: ${470}px) {
    font-size: 20px;
    white-space: unset;
  }
`;
