import * as React from "react"
import {StyledPricing} from "./Pricing.style";
import {Title} from "../../common/components/title/Title.component";
import {Colors} from "../../common/style/ColorUtils";
import {PricingSection} from "./pricingSection/PricingSection.component";
import {getAllPiercings} from "./service/Pricing.service";
import {AreYouInterestedComponent} from "./areYouInterestedComponent/AreYouInterestedComponent.component";

export const PricingComponent = () => {
    const getBackgroundColor = (index: number): string => {
        return index % 2 === 0 ? Colors.BLACK : Colors.WHITE;
    };

    const getTextColor = (index: number): string => {
        return index % 2 === 0 ? Colors.WHITE : Colors.BLACK;
    };

    return <StyledPricing>
        <Title title={"PRICING"} panelsColor={Colors.WHITE} textColor={Colors.WHITE}/>
        {getAllPiercings().map((value, index: number) =>
            <PricingSection
                key={value.title}
                backgroundColor={getBackgroundColor(index)}
                textColor={getTextColor(index)}
                title={value.title}
                items={value.pricing}
                description={value.description}
            />
        )}
        <AreYouInterestedComponent/>
    </StyledPricing>;
}
