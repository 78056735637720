import styled from "styled-components";
import {Colors} from "../../../../common/style/ColorUtils";

export const StyledPricingRow = styled.div`
  border-bottom: 3px solid ${Colors.YELLOW};
  padding-bottom: 7px;
  font-family: bely-italic;
  padding-top: 25px;
  
  .right-column {
    text-align: right;
  }
`;
