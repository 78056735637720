import React, {FC} from "react";
import {StyledPricingSection} from "./PricingSection.style";
import {PriceRow} from "../service/PricingSection.type";
import {PricingRow} from "./pricingRow/PricingRow.component";
import {isDefined} from "../../../common/util/ObjectUtils";
import {StyledPricingDescription} from "./pricingSubtitle/PricingSubtitle.style";
import {Translate} from "../../../lang/Translate.component";
import {SmallTitle} from "../../../common/components/smallTitle/SmallTitle.component";

interface PricingSectionProps {
    backgroundColor: string;
    textColor: string;
    title: string;
    items: PriceRow[];

    description?: string;
}

export const PricingSection: FC<PricingSectionProps> = (props: PricingSectionProps) => {
    return <StyledPricingSection backgroundColor={props.backgroundColor} textColor={props.textColor}>
        <SmallTitle
            title={props.title}
            backgroundColor={props.textColor}
            textColor={props.backgroundColor}
        />
        {props.items.map(item =>
            <PricingRow key={item.name} value={item} textColor={props.textColor}/>
        )}
        {isDefined(props.description) &&
            <StyledPricingDescription>
                <div className={"star"}>*</div>
                <div><Translate translationKey={props.description as string}/></div>
            </StyledPricingDescription>
        }
    </StyledPricingSection>;
};
