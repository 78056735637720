import React, {FC} from "react";
import {StyledTitle} from "./Title.style";
import {Translate} from "../../../lang/Translate.component";

export interface TitleProps {
    title: string;
    panelsColor: string
    textColor?: string
}

export const Title: FC<TitleProps> = (props: TitleProps) => {
    return <StyledTitle {...props}>
        <div className={"page-title-container"}>
            <div className={"page-left-panel"}></div>
            <div className={"page-title"}>
                <Translate translationKey={props.title}/>
            </div>
            <div className={"page-right-panel"}></div>
        </div>
    </StyledTitle>;
};
