import styled from "styled-components";
import {FontSizes} from "../../style/FontUtils";
import {TitleProps} from "./Title.component";
import {SM_MAX_SIZE} from "../../util/ViewSizeUtils";

export const StyledTitle = styled.h2<TitleProps>`
  margin: 0;
  
  .page-title-container {  
    width: 100%;
    display: inline-flex;
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: ${FontSizes.MEDIUM};
    line-height: ${FontSizes.MEDIUM};
    height: 60px;
    font-family: bely-bold;
  }
  
  .page-left-panel {
    height: 9px;
    width: calc(40vw - 140px);
    margin-left: auto;
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 140px);
    }
    background-color: ${(props) => props.panelsColor};
    margin-top: auto;
    margin-bottom: auto;
  }

  .page-right-panel {
    height: 9px;
    margin-right: auto;
    width: calc(40vw - 140px);
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: calc(50vw - 140px);
    }
    background-color: ${(props) => props.panelsColor};
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .page-title {
    width: 280px;
    font-weight: 100;
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      width: 280px;
    }
    text-align: center;
    color: ${(props) => props.textColor};
  }
`;
