import styled from "styled-components";

export const StyledPricingDescription = styled.div`
  mix-blend-mode: difference;
  font-size: 20px;
  margin-top: 40px;
  font-family: bely;
  white-space: pre-wrap;
  display: flex;

  .star {
    padding-right: 2px;
  }
  
  @media only screen and (max-width: ${470}px) {
    font-size: 17px;
  }
`;
