import React, {FC} from "react";
import {StyledPricingRow} from "./PricingRow.style";
import {PriceRow} from "../../service/PricingSection.type";
import {Translate} from "../../../../lang/Translate.component";

interface PricingRowProps {
    value: PriceRow;
    textColor: string;
}

export const PricingRow: FC<PricingRowProps> = (props: PricingRowProps) => {
    return <StyledPricingRow className={"row"}>
        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 left-column">
            <Translate translationKey={props.value.name}/>
        </div>
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 right-column">
            <Translate translationKey={props.value.price}/>
        </div>
    </StyledPricingRow>;
};
