import React, {FC} from "react";
import {StyledAreYouInterestedComponent} from "./AreYouInterestedComponent.style";
import {Translate} from "../../../lang/Translate.component";
import {LocalizedLink} from "../../../common/components/localizedLink/LocalizedLink.component";
import {getBookingsLink} from "../../../common/components/menu/service/Links.service";

interface AreYouInterestedComponentProps {
}

export const AreYouInterestedComponent: FC<AreYouInterestedComponentProps> = (props: AreYouInterestedComponentProps) => {
    return <StyledAreYouInterestedComponent>
        <div className={"label"}><Translate translationKey={"ARE_YOU_INTERESTED"}/></div>
        <LocalizedLink className={"bookings-button"} label={"BOOKINGS"} to={getBookingsLink()}/>
    </StyledAreYouInterestedComponent>;
};
