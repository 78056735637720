import styled from "styled-components";
import {Colors} from "../../../common/style/ColorUtils";

export const StyledAreYouInterestedComponent = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 12px;
  
  .label {
    font-family: bely-bold;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 26px;
    padding-bottom: 5px;
    color: ${Colors.WHITE};
  }
  
  .bookings-button {
    display: inline-block;
    text-decoration: none;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 35px;
    padding-left: 35px;

    background-color: ${Colors.WHITE};
    font-weight: bold;
    border-radius: 7px;
    width: 80px;
    line-height: 30px;
    text-align: center;
    color: black;
    font-size: 23px;
  }
`;
