import {PriceRow, PriceSection} from "./PricingSection.type";

export const getAllPiercings = (): PriceSection[] => [
    {title: "TATTOS", pricing: getTattooPricing(), description: "TATTOOS_PRICING_SUBTITLE"},
    // {title: "TOOTH_GEMS", pricing: getToothGemsPricing()},
    // {title: "TOOTH_GEMS_CUSTOM", pricing: getCustomToothGemsPricing()},
    // {title: "REMOVING_TOOTH_GEMS", pricing: getRemovingToothGemsPricing()},
    {title: "EAR", pricing: getEarPiercing()},
    {title: "NIPPLES", pricing: getNipplePiercing()},
    {title: "NOSE", pricing: getNosePiercing()},
    {title: "LIPS", pricing: getLipsPiercing()},
    {title: "TONGUE", pricing: getTonguePiercing()},
    {title: "BELLY_BUTTON", pricing: getBellyButtonPiercing()},
    {title: "BREW", pricing: getBrewPiercing()},
    {title: "OTHER", pricing: getOtherPiercing()},
]
export const getTattooPricing = (): PriceRow[] =>
    [
        {name: "MINIMAL_PRICE", price: "MINIMAL_PRICE_PRICE"},
        {name: "SMALL_TATTOO", price: "SMALL_TATTOO_PRICE"},
        {name: "WHOLE_DAY_SESSION", price: "WHOLE_DAY_SESSION_PRICE"},
    ];

export const getToothGemsPricing = (): PriceRow[] =>
    [
        {name: "BORDER_COMPOSITION", price: "BORDER_COMPOSITION_PRICE"},
        {name: "BUTTERFLY_COMPOSITION", price: "BUTTERFLY_COMPOSITION_PRICE"},
        {name: "FLOWER_COMPOSITION", price: "FLOWER_COMPOSITION_PRICE"},
        {name: "CROSS_COMPOSITION", price: "CROSS_COMPOSITION_PRICE"},
        {name: "PAW_COMPOSITION", price: "PAW_COMPOSITION_PRICE"},
        {name: "DISCO_COMPOSITION", price: "DISCO_COMPOSITION_PRICE"},
    ];

export const getCustomToothGemsPricing = (): PriceRow[] =>
    [
        {name: "ONE_GEM", price: "ONE_GEM_PRICE"},
        {name: "TWO_GEMS", price: "TWO_GEMS_PRICE"},
        {name: "THREE_GEMS", price: "THREE_GEMS_PRICE"},
        {name: "FOUR_GEMS", price: "FOUR_GEMS_PRICE"},
        {name: "FIVE_SIX_GEMS", price: "FIVE_SIX_GEMS_PRICE"},
        {name: "SEVEN_ELEVEN_GEMS", price: "SEVEN_ELEVEN_GEMS_PRICE"},
        {name: "ELEVEN_FIFTEEN_GEMS", price: "ELEVEN_FIFTEEN_GEMS_PRICE"},
        {name: "ANOHTER_GEM", price: "ANOHTER_GEM_PRICE"},
    ];

export const getRemovingToothGemsPricing = (): PriceRow[] =>
    [
        {name: "OUR_ONE_SIX_GEMS_REMOVING", price: "OUR_ONE_SIX_GEMS_REMOVING_PRICE"},
        {name: "OUR_SEVEN_PLUS_GEMS_REMOVING", price: "OUR_SEVEN_PLUS_GEMS_REMOVING_PRICE"},
        {name: "SOMEONE_ONE_SIX_GEMS_REMOVING", price: "SOMEONE_ONE_SIX_GEMS_REMOVING_PRICE"},
        {name: "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING", price: "SOMEONE_ELSE_SEVEN_PLUS_GEMS_REMOVING_PRICE"},
    ];

export const getEarPiercing = (): PriceRow[] => [
    {name: "LOBE", price: "LOBE_PRICE"},
    {name: "2_LOBE", price: "2_LOBE_PRICE"},
    {name: "HELIX", price: "HELIX_PRICE"},
    {name: "FORWARD_HELIX", price: "FORWARD_HELIX_PRICE"},
    {name: "VERTICAL_HELIX", price: "VERTICAL_HELIX_PRICE"},
    {name: "FLAT", price: "FLAT_PRICE"},
    {name: "CONCH", price: "CONCH_PRICE"},
    {name: "DAITH", price: "DAITH_PRICE"},
    {name: "ROOK", price: "ROOK_PRICE"},
    {name: "SNUG", price: "SNUG_PRICE"},
    {name: "TRAGUS", price: "TRAGUS_PRICE"},
    {name: "ANTI_TRAGUS", price: "ANTI_TRAGUS_PRICE"},
    {name: "INDUSTRIAL", price: "INDUSTRIAL_PRICE"},
    {name: "ORBITAL", price: "ORBITAL_PRICE"},
    {name: "RAGNAR", price: "RAGNAR_PRICE"},
];

export const getNipplePiercing = (): PriceRow[] => [
    {name: "LEFT_NIPPLE", price: "LEFT_NIPPLE_PRICE"},
    {name: "RIGHT_NIPPLE", price: "RIGHT_NIPPLE_PRICE"},
    {name: "BOTH_NIPPLES", price: "BOTH_NIPPLES_PRICE"},
];

export const getNosePiercing = (): PriceRow[] => [
    {name: "NOSTRIL", price: "NOSTRIL_PRICE"},
    {name: "SEPTUM", price: "SEPTUM_PRICE"},
    {name: "BRIDGE", price: "BRIDGE_PRICE"},
    {name: "MANTIS", price: "MANTIS_PRICE"},
];

export const getLipsPiercing = (): PriceRow[] => [
    {name: "LABRET", price: "LABRET_PRICE"},
    {name: "MONROE", price: "MONROE_PRICE"},
    {name: "MADONNA", price: "MADONNA_PRICE"},
    {name: "SMILEY", price: "SMILEY_PRICE"},

    {name: "MEDUSA", price: "MEDUSA_PRICE"},
    {name: "JESTRUM", price: "JESTRUM_PRICE"},

    {name: "BITES", price: "BITES_PRICE"},
    {name: "ASHLEY", price: "ASHLEY_PRICE"},

];

export const getTonguePiercing = (): PriceRow[] => [
    {name: "STANDARD_TONGUE", price: "STANDARD_TONGUE_PRICE"},
    {name: "VENOM", price: "VENOM_PRICE"},

];

export const getBellyButtonPiercing = (): PriceRow[] => [
    {name: "NAVEL", price: "NAVEL_PRICE"},
    {name: "REVERSE_NAVEL", price: "REVERSE_NAVEL_PRICE"},
];

export const getBrewPiercing = (): PriceRow[] => [
    {name: "BREW_PIERCING", price: "BREW_PIERCING_PRICE"},
];

export const getOtherPiercing = (): PriceRow[] => [
    {name: "OUR_CONSULTATION", price: "OUR_CONSULTATION_PRICE"},
    {name: "BAD_CLIENT_CONSULTATION", price: "BAD_CLIENT_CONSULTATION_PRICE"},
    {name: "OTHER_PIERCER_CONSULTATION", price: "OTHER_PIERCER_CONSULTATION_PRICE"},
    {name: "CHANGE_PIERCING", price: "CHANGE_PIERCING_PRICE"},
    {name: "OTHER_PIERCER_CHANGE_PIERCING", price: "OTHER_PIERCER_CHANGE_PIERCING_PRICE"},
];
